export function $create<T extends ApiEntity>({
  resourceName,
  values,
}: {
  resourceName: ResourceName
  values: any
  deserialize?: boolean
}) {
  return $update<T>({ resourceName, values, method: 'POST', deserialize: true })
}
